import { useState } from "react";
import { Box, Heading, Container, Text, Button, Stack, Icon, useColorModeValue,
  createIcon, Flex, FormControl, FormHelperText, FormLabel, Input, Link, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { RelayUrlInput } from "../../components/relay-url-input";
import { normalizeToHexPubkey } from "../../helpers/nip19";
import accountService from "../../services/account";
import { COMMON_CONTACT_RELAY } from "../../const";
import QRCodeScannerButton from "../../components/qr-code/qr-code-scanner-button";

export default function TermsView() {
  const navigate = useNavigate();
  const toast = useToast();
  const [npub, setNpub] = useState("");
  const [relayUrl, setRelayUrl] = useState(COMMON_CONTACT_RELAY);

  const handleSubmit: React.FormEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
  };

  return (
    <Container maxW={'3xl'}>
    <Stack
          as={Box}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}>
          <Heading textAlign={'center'}
            fontWeight={600}
            lineHeight={'110%'}>
            Regulamin określający zasady korzystania z serwisu społecznościowego <br />
            <Text  as={'span'} >
               KLEPAK
            </Text>
          </Heading>
          <Text color={'BlueGray.500'}>  Witamy na polskim portalu społecznościowym Klepak, zasobie internetowym, który broni polskiej rodziny i tradycyjnych
          <Link href='https://calapolskaczytadzieciom.pl/dla-edukatorow/nauczanie-wartosci/uniwersalne-wartosci-moralne/' target='...' >
           {" "} wartości moralnych. {""}
          </Link> <Text as='b'>Jesteśmy tolerancyjni, ale tolerancja musi mieć granice... </Text>
            Będziemy szczęśliwi, jeśli przyczynimy się do powstrzymania degradacji polskiego społeczeństwa.
             Bardzo ważne w takim przypadku jest poczucie sprawiedliwości i wolności w społeczeństwie. Wolność wymaga odporności na cenzurę polityczną a Klepak właśnie to zapewnia.
              Klepak korzysta ze zdecentralizowanej i otwartej sieci społecznościowej PRL+, opartej na protokole Nostr.
              Dzięki PRL+ twoje interakcje społeczne są chronione przed wszelkimi centro kontrolami, co zapewnia, platformę wolną od reklam, toksycznych algorytmów i cenzury.
              Jeżeli uważasz że twoje dobra osobiste zostały rażąco naruszone możesz to zgłosić do administratora
               <Link href='https://www.prl.plus' > sieci PRL+</Link>.
          </Text>

          <Text >
          <p><b>Prywatność</b></p>
          Prywatność w internecie to fikcja. Prawo do prywatności działa tylko w realu.
          Jeśli chcesz zachować pozory prywatności to <b>nigdy na żadnym serwisie nie podawaj swoich prawdziwych danych !!!</b>   </Text>
          <Text > Każdy Użytkownik odpowiada prawnie za swoje wypowiedzi.
          Administracja portalu nie ponosi odpowiedzialności za publikowane materiały.
          <p/>

          Jako użytkownik zobowiązujesz się:
<p>- unikać tzw. brzydkich wyrazów i wulgarnych wpisów.</p>
<p>- zapewnić wiarygodność przekazywanych informacji.</p>
<p>- podczas kopiowania informacji z innych źródeł, włączyć w jej skład informacje o autorze.</p>
<p>- nie rozpowszechnianiać informacji, która ma na celu podżeganie do wojny, nienawiści narodowej, rasowej lub religijnej.</p>
<p>- nie publikować linków do materiałów pochodzących z portalu Fbook.pl (meta) </p>
<p>- nie zakłócać działanie serwisu.</p>
<p>- nie otwierać więcej niż dwóch kont, faktycznie należących do jednej i tej samej osoby.</p>
<p>- nie wykonywać czynności, mających na celu wprowadzenie innych użytkowników w błąd.</p>
<p>- nie umieszczać materiałów adware, erotycznych, pornograficznych.</p>
<p>- nie używać skryptów (programów) do automatycznego zbierania informacji.</p>
          </Text>
          <Text >
          Linki do innych stron
<p>Witryna może zawierać linki lub linki do informacji, które nie zostały opracowane, zweryfikowane lub przetestowane przez nas lub któregokolwiek z naszych pracowników lub agentów. Są one dostarczane wyłącznie w celach informacyjnych. Nie ponosimy żadnej odpowiedzialności za te informacje ani nie potwierdzamy ich dokładności ani kompletności. Nie gwarantujemy, że informacje lub udostępnianie hiperłączy nie narusza praw osób trzecich.
 Nie ponosimy odpowiedzialności za nie ani za jakiekolwiek dostępne treści, ani za jakiekolwiek straty lub szkody, które mogą wyniknąć z ich wykorzystania. </p>
</Text>

<Text>
  Klepak jest niezależnym i bezstronnym portalem nie popierającym żadnego ugrupowania politycznego
   oraz nie otrzymującym żadnych dóbr materialnych
    od instytucji oraz osób obcego obywatelstwa.<p>
          Korzystając z serwisu, zgadzasz się zgadzasz się z warunkami niniejszego regulaminu oraz z tym że wyłączenia odpowiedzialności określone w niniejszym dokumencie są rozsądne i uzasadnione.
           Jeśli nie uważasz ich za uzasadnione, nie powinieneś korzystać z witryny klepak.net!</p>
          <p>Regulamin korzystania z usługi może ulec zmianie w dowolnym momencie
           w przyszłości bez wyraźnego powiadomienia.</p></Text>

          <Stack
            direction={'column'}
            spacing={3}
            position={'relative'}>
            <Box>
              <Text
                fontSize={'lg'}
                fontFamily={'Caveat'}
                position={'absolute'}
                right={'-5px'}
                top={'-15px'}
                transform={'rotate(10deg)'}>
                Akceptuję niniejszy regulamin !
              </Text>
            </Box>
            <Flex justifyContent="space-between" gap="2">
            <Link href='https://pl1.tv' >
             <Button opacity={0.8}>
                Opuść stronę
                </Button>
             </Link>
              <Button colorScheme={'green'}
              bg={'green.400'}
              rounded={'full'} onClick={() => navigate("../../../signin")}>
            Wchodzę w to
              </Button>
            </Flex>
          </Stack>
        </Stack>
    </Container>
  );
}
